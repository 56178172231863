<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 d-flex justify-content-between">
        <h2 class="w-75">オーナー管理</h2>
        <div class="w-25 py-3">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            TOPへ
          </button>
          <button
            v-on:click="createUser()"
            class="float-right button-create-user mr-2"
          >
            新規登録
          </button>
        </div>
      </div>
      <Tables :items="listOwner.data" :fields="fields" :itemsPerPageSelect="{}">
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:no="{ index }">
          <td>
            <!-- {{
              listOwner.total -
              (listOwner.current_page - 1) * listOwner.per_page -
              index
            }} -->
            {{ (listOwner.current_page - 1) * listOwner.per_page + index + 1 }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div class="w-5 d-flex">
              <CButton
                class="mx-1"
                style="width: 67px"
                color="primary"
                square
                v-on:click="editOwner(item.id, index)"
                variant="outline"
                size="sm"
              >
                編集
              </CButton>
              <CButton
                class="mx-1"
                style="width: 67px"
                color="danger"
                square
                v-on:click="deleteOwners(item)"
                variant="outline"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listOwner.total"
      >
        <p class="font-weight-bold">
          {{ listOwner.total }}件中{{ listOwner.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listOwner.last_page"
      >
        <CPagination
          v-if="listOwner.total"
          :activePage.sync="page"
          :pages="listOwner.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-owner" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除してもよろしいですか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="blockNewUser()"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-owner')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListUser",
  data() {
    return {
      fields: tableFields.LIST_OWNER,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      idOwner: null,
      shopIdOwner: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListOwners(this.page);
    const interval = setInterval(function () {
      if (document.getElementsByClassName("footer").length > 0) {
        document.getElementsByClassName("footer")[0].style.position = "fixed";
        document.getElementsByClassName("footer")[0].style.bottom = 0;
        clearInterval(interval);
      }
    }, 50);
  },
  beforeDestroy() {
    document
      .getElementsByClassName("footer")[0]
      .style.removeProperty("position");
    document.getElementsByClassName("footer")[0].style.removeProperty("bottom");
  },
  computed: {
    ...mapGetters(["listOwner", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-owner");
        this.getListOwners(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listOwner() {
      this.page = this.listOwner.current_page;
    },
    page() {
      this.getListOwners(this.page);
    },
  },
  methods: {
    ...mapActions({ getListOwner: "getListOwner" }),
    ...mapActions({ deleteOwner: "deleteOwner" }),
    createUser() {
      this.$router.push({
        name: "create owner",
        query: { total: this.listOwner.total ? this.listOwner.total + 1 : 1 },
      });
    },
    showConfirm(id) {
      this.idOwner = id;
    },
    getListOwners(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        limit: this.limit,
      };
      this.getListOwner(formData);
    },
    async blockNewUser() {
      const formBlock = {
        id: this.idOwner,
        user_type: "1",
        is_active: 0,
        shop_id: this.shopIdOwner,
      };
      await this.deleteOwner(formBlock);
      this.$nextTick(() => {
        this.$bvModal.hide("delete-modal-user");
      });
      this.getListOwners(this.page);
    },
    editUser(id) {
      this.$router.push({ name: "edit user", params: { id: id } });
    },
    changeLimitPage() {
      this.getListUsers(1);
    },
    gotoDashboard() {
      this.$router.push({ name: "dashboard cdea" });
    },
    resetFieldSearch() {
      this.name = "";
      this.email = "";
      this.dateRegis_from = "";
      this.dateRegis_to = "";
      this.dateLogout_from = "";
      this.dateLogout_to = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          name: this.name,
          email: this.email,
          created_from: this.dateRegis_from,
          created_to: this.dateRegis_to,
          logout_from: this.dateLogout_from,
          logout_to: this.dateLogout_to,
          limit: this.limit,
        },
      };
      this.getListUser(formData);
    },
    deleteOwners(value) {
      this.idOwner = value.id;
      this.shopIdOwner = value.shop_id;
      this.$bvModal.show("delete-modal-owner");
    },
    editOwner(id, index) {
      this.$router.push({
        name: "edit owner",
        params: {
          id: id,
        },
        query: {
          NO:
            (this.listOwner.current_page - 1) * this.listOwner.per_page +
            index +
            1,
        },
      });
    },
  },
};
</script>
